.home-page-4 {

    header,
    .sticky-header {
        background-color: var(--green-900);
    }

    .logo,
    .icon-caret,
    .burger-icon {
        filter: brightness(0) invert(1);
    }

    .menu-link {
        color: white
    }

}

.about-2-page {

    header,
    .sticky-header {
        background-color: var(--bg-2);
    }
}

.services-1-page {

    header,
    .sticky-header {
        background-color: var(--bg-5);
    }
}

.faqs-1-page {

    .logo,
    .icon-caret,
    .burger-icon {
        filter: brightness(0) invert(1);
    }

    .menu-link {
        color: white
    }

    header,
    .sticky-header {
        background-color: #2e848c;
    }
}

.burger-icon {
    position: absolute;
    width: 24px;
    height: 20px;
    cursor: pointer;
    // -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    // transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    top: 50%;
    transform: translateY(-50%);
    right: 13px;
    z-index: 1002;
    // transform: translateY(50%);

    @media (min-width: 768px) {
        right: 37px;
        display:none;
    }

    &.burger-icon-white>span::before,
    &.burger-icon-white>span::after {
        background-color: var(--gray-500);
    }

    &>span {
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;

        &::before,
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
        }
    }

    &>span.burger-icon-top {
        top: 2px;
    }

    &>span.burger-icon-mid {
        top: 9px;
    }

    &>span.burger-icon-bottom {
        bottom: 2px;
    }


    &.burger-close {
        filter: invert(8%) sepia(34%) saturate(870%) hue-rotate(181deg) brightness(97%) contrast(98%) !important;

        @media screen and (max-width: 1023.98px) {
            position: fixed;
            right: 10px;
            top: 20px;
        }



        >span.burger-icon-top {
            display: none;
            opacity: 0;
        }

        >span.burger-icon-mid {
            top: 8px;
            transform: rotate(45deg);
        }

        >span.burger-icon-bottom {
            bottom: 10px;
            transform: rotate(-45deg);
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 11;
    display: none;
    cursor: crosshair;
}

.overlay.show {
    display: initial;
}

header.sticky-header {
    position: fixed !important;
    top: 0;
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 5%);
    z-index: 100;
    background-color: #fff;
    animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
}

nav {
    .menu-mobile-item {
        &.expand {
            .menu-child {
                display: block;
            }

            img {
                transform: rotate(180deg);
            }
        }
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}